import React from 'react';

function ContactView() {
  return (
    <div className="contact-view">
      <h2>Contact</h2>
      <p>Contact information will be added here.</p>
    </div>
  );
}

export default ContactView;